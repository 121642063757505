import React from 'react';
import linkifyHtml from 'linkify-html';
import cx from 'classnames';
import ReactPlayer from 'react-player';

import { convertMarkdownToFormatted, sanitizer } from 'util/stringUtils';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import Avatar from 'components/Avatar/Avatar';
import { navigate } from '@reach/router';

const FeedCardCauze = ({ event, href, hasComment }) => {
  const prepareFeedItemBody = (body) => {
    let transformed = linkifyHtml(body || '', { defaultProtocol: 'https' });
    transformed = convertMarkdownToFormatted(transformed);
    transformed = sanitizer(transformed, {
      ADD_ATTR: ['target'],
    });
    return transformed;
  };

  return (
    <a
      href={href}
      className={cx('feed-card-cauze', { 'has-comment': hasComment })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate(href);
      }}
    >
      <div className="feed-card-cauze-header">
        <div
          className={cx('feed-card-name', {
            'has-description': Boolean(event.description),
          })}
        >
          {event.name}
        </div>
        <div className="feed-card-description">
          <div
            dangerouslySetInnerHTML={{
              __html: prepareFeedItemBody(event.description),
            }}
          />
        </div>
      </div>
      {event.previewUrl ? (
        <div className="video-container">
          <div className="event-video">
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url={event.previewUrl}
            />
          </div>
        </div>
      ) : (
        <div
          className={cx('feed-card-body', {
            'no-image': !event.image?.lg,
          })}
          style={{
            backgroundImage: event.image?.lg
              ? `url('${event.image?.lg}')`
              : undefined,
          }}
        />
      )}
      <div className="feed-card-footer">
        {Boolean(event.purchaserCount) && Boolean(event.topPurchasers?.length) && (
          <div className="feed-card-givers">
            <div className="feed-card-footer-title">
              <LargeNumber amount={event.purchaserCount} /> Giver
              {event.purchaserCount !== 1 && 's'}:
            </div>
            <div>
              {event.topPurchasers?.slice(0, 4).map((topPurchaser) => (
                <Avatar
                  key={topPurchaser.id}
                  xsm
                  avatarUrls={topPurchaser.actor.avatarUrls}
                />
              ))}
            </div>
          </div>
        )}
        {Boolean(
          Array.isArray(event.projects) && event.projects.length > 0,
        ) && (
          <div className="feed-card-nonprofits">
            <div className="feed-card-footer-title">
              {event.projects.length} Nonprofit
              {event.projects.length !== 1 && 's'}
            </div>
            <div>
              {event.projects?.slice(0, 4).map((project) => (
                <Avatar key={project.id} xsm avatarUrls={project.avatarUrls} />
              ))}
            </div>
          </div>
        )}
        {Boolean(event.purchaserCount > 3 && event.current > 0) && (
          <div className="feed-card-raised">
            <div className="feed-card-footer-title">Raised</div>
            <div className="feed-card-raised-amount">
              <LargeNumber
                isCurrency
                amount={parseInt(event.current || 0) / 100}
              />
            </div>
          </div>
        )}
      </div>
    </a>
  );
};

export default FeedCardCauze;
