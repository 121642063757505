import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { emailValidate } from '../../util/formFieldValidators';

const parseEmails = debounce(
  ({
    emailListString,
    setDupeCount,
    updateEmails,
    setEmailsArray,
    existingEmails,
    setBadEmails,
    setHasValidationError,
  }) => {
    let invalidEmails = [];
    let parsedEmailArray = emailListString
      .toLowerCase()
      .replace(/\s/g, ',')
      .split(',')
      .filter((email) => {
        if (emailValidate(email)) {
          return true;
        } else {
          if (email !== '') {
            invalidEmails.push(email);
          }
        }
        return false;
      });
    let duplicateEmails = [];
    let dedupedEmails = parsedEmailArray.filter((email, index) => {
      if (
        parsedEmailArray.indexOf(email) !== index ||
        existingEmails.includes(email)
      ) {
        duplicateEmails.push(email);
        return false;
      }
      return true;
    });
    if (invalidEmails.length > 0) {
      setBadEmails(invalidEmails);
    } else {
      setBadEmails([]);
    }
    setDupeCount(duplicateEmails.length);
    updateEmails(dedupedEmails);
    setEmailsArray(dedupedEmails);
    if (dedupedEmails.length === 0 || invalidEmails.length > 0) {
      setHasValidationError(true);
    } else {
      setHasValidationError(false);
    }
  },
  500,
  { leading: false, trailing: true },
);

const EmailListInput = ({
  updateEmails,
  existingEmails = [],
  setHasValidationError,
  baseEmailInputText,
}) => {
  const [emailInputText, setEmailInputText] = useState(baseEmailInputText);
  const [emailsArray, setEmailsArray] = useState(
    [baseEmailInputText].filter((email) => email),
  );
  const [dupeCount, setDupeCount] = useState(0);
  const [badEmails, setBadEmails] = useState([]);

  useEffect(() => {
    if (baseEmailInputText) {
      parseEmails({
        emailListString: baseEmailInputText,
        setDupeCount,
        updateEmails,
        setEmailsArray,
        setEmailInputText,
        dupeCount,
        existingEmails,
        setBadEmails,
        setHasValidationError,
      });
    }
  }, []);

  return (
    <div>
      <textarea
        placeholder="Enter multiple comma-separated email addresses...name@sample.com, name2@sample.com"
        className="textarea email-list-input"
        onChange={(ev) => {
          setEmailInputText(ev.target.value);
          parseEmails({
            emailListString: ev.target.value,
            setDupeCount,
            updateEmails,
            setEmailsArray,
            setEmailInputText,
            dupeCount,
            existingEmails,
            setBadEmails,
            setHasValidationError,
          });
        }}
        value={emailInputText}
      />
      <div className="email-summary">
        {emailsArray.length} emails added
        {dupeCount > 0 && `, ${dupeCount} duplicates ignored`}
        {badEmails.length > 0 && (
          <span>
            ,{' '}
            <span className="has-text-danger">
              {badEmails.length} Errors Found
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

EmailListInput.propTypes = {
  updateEmails: PropTypes.func.isRequired,
  existingEmails: PropTypes.array,
  setHasValidationError: PropTypes.func,
};

export default EmailListInput;
