import React, { Fragment, useEffect, useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';

import WrappedPaypalButton from 'components/WrappedPaypalButton';
import WrappedPlaidButton from 'components/WrappedPlaidButton';
import WrappedApplePayButton from 'components/WrappedApplePayButton';
import Currency from '../Currency/Currency';

import stripeOptions from '../../config/stripe';

const renderPaymentMethodDetails = ({
  paymentMethod,
  feeDescription,
  paypalValidate,
  plaidValidate,
  store,
}) => {
  let paymentElement;
  const payMethod = paymentMethod?.toUpperCase();

  if (payMethod === 'PAYPAL') {
    paymentElement = (
      <WrappedPaypalButton
        {...store.getPaypalVars()}
        validateFunc={paypalValidate}
      />
    );
  }

  if (payMethod === 'APPLE_PAY') {
    paymentElement = <WrappedApplePayButton store={store} />;
  }

  if (payMethod === 'CARD') {
    paymentElement = <PaymentElement options={stripeOptions.cardElement} />;
  }

  if (payMethod === 'ACH') {
    paymentElement = (
      <WrappedPlaidButton
        linkToken={store.plaidLinkToken}
        validateFunc={plaidValidate}
        onLinkStart={store.onPlaidStart}
        onLinkSuccess={store.onPlaidSuccess}
        onLinkError={store.onPlaidError}
      />
    );
  }

  return (
    <Fragment>
      <pre className="fee-description">{feeDescription}</pre>
      {paymentElement}
    </Fragment>
  );
};

const PaymentOptionSelector = ({
  store,
  activeEntity,
  allowNegativeBalance = false,
  excludedPaymentTypes = [], // filter balance and apple pay for selfgift
}) => {
  const [includeApplePay, setIncludeApplePay] = useState(false);

  const balance = store.paymentOptions.find(
    (option) => option.paymentType === 'BALANCE',
  )?.balance;

  useEffect(() => {
    if (
      balance &&
      balance.total - currentCheckout.amount < 0 &&
      !allowNegativeBalance &&
      store.currentCheckout.paymentType === 'BALANCE'
    ) {
      store.updateCheckout({ paymentType: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.currentCheckout.amount]);

  const paymentOptions = store.paymentOptions;
  const currentCheckout = store.currentCheckout;

  // validate needs to be async for paypal modal callbacks to work correctly
  const paypalValidate = async () => {
    if (currentCheckout.amount === 0) {
      store.popErrorModal('Please select an amount.');
      return false;
    }
    return true;
  };

  const plaidValidate = () => {
    if (currentCheckout.amount === 0) {
      store.popErrorModal('Please select an amount.');
      return false;
    }

    if (
      currentCheckout.giftType === 'USER_GIFT' &&
      currentCheckout.emails?.length === 0
    ) {
      store.popErrorModal('Please enter at least one email address.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!window.ApplePaySession) {
      setIncludeApplePay(false);
      return;
    }

    window.ApplePaySession.canMakePaymentsWithActiveCard(
      process.env.REACT_APP_APPLE_MERCHANT_ID,
    ).then((result) => setIncludeApplePay(result));
  }, []);

  return (
    <div className="input-group payment-option-selector">
      {paymentOptions
        .filter(
          (paymentOption) =>
            !excludedPaymentTypes.includes(paymentOption.paymentType),
        )
        .filter(
          (paymentOption) =>
            paymentOption.paymentType?.toUpperCase() !== 'APPLE_PAY' ||
            (paymentOption.paymentType?.toUpperCase() === 'APPLE_PAY' &&
              includeApplePay),
        )
        .map((paymentOption, index) => (
          <Fragment key={`${paymentOption}-${index}`}>
            {paymentOption.paymentType === 'BALANCE' && (
              <label
                className={
                  paymentOption.balance.total - currentCheckout.amount < 0 &&
                  !allowNegativeBalance
                    ? 'payment-option-disabled'
                    : 'payment-option'
                }
                key={`balance`}
              >
                <input
                  onChange={(_ev) => {
                    store.updateCheckout({
                      paymentType: 'BALANCE',
                      cardId: null,
                      cardBrand: null,
                      cardName: null,
                      cardNickname: null,
                    });
                  }}
                  type="radio"
                  value="BALANCE"
                  checked={currentCheckout.paymentType === 'BALANCE'}
                  disabled={
                    paymentOption.balance.total - currentCheckout.amount < 0 &&
                    !allowNegativeBalance
                  }
                />
                From <Currency showCents amount={paymentOption.balance.total} />{' '}
                {activeEntity.entityType === 'INFLUENCER' && 'Influencer '}
                {activeEntity.entityType === 'COMPANY' && 'Company '}
                Balance
              </label>
            )}
            {paymentOption.paymentType === 'CARD' &&
              paymentOption.cards.length > 0 &&
              paymentOption.cards.map((card, index) => (
                <Fragment key={`${card.id}-${index}`}>
                  <label className="payment-option" key={`CARD-${index}`}>
                    <input
                      onChange={(_ev) => {
                        store.updateCheckout({
                          paymentType: card.accountType,
                          cardId: card.id,
                          cardBrand: card.brand,
                          cardName: card.last4,
                          cardNickname: card.nickname,
                          cardStripeId: card.stripeCardId,
                        });
                      }}
                      type="radio"
                      value={card.cardId}
                      checked={
                        currentCheckout.paymentType === card.accountType &&
                        currentCheckout.cardId === card.id
                      }
                    />
                    {card.nickname || `${card.brand} ${card.last4}`}
                  </label>
                  {currentCheckout.paymentType === card.accountType &&
                    currentCheckout.cardId === card.id && (
                      <div className="payment-method-details">
                        <pre className="fee-description-no-element">
                          {card.feeDescription}
                        </pre>
                      </div>
                    )}
                </Fragment>
              ))}
            {paymentOption.paymentType !== 'BALANCE' &&
              paymentOption.paymentType !== 'ACH' && (
                <label
                  className="payment-option"
                  key={`${paymentOption.type}-${index}`}
                >
                  <input
                    onChange={(ev) => {
                      store.updateCheckout({
                        paymentType: ev.target.value,
                        cardId: null,
                        cardBrand: null,
                        cardName: null,
                        cardNickname: null,
                        cardStripeId: null,
                      });
                    }}
                    type="radio"
                    value={paymentOption.paymentType}
                    checked={
                      paymentOption.paymentType?.toUpperCase() ===
                        currentCheckout.paymentType?.toUpperCase() &&
                      !(
                        currentCheckout.paymentType === 'CARD' &&
                        currentCheckout.cardId !== null
                      )
                    }
                    disabled={
                      paymentOption.paymentType === 'APPLE_PAY' &&
                      !store.currentCheckout.amount
                    }
                  />
                  {paymentOption.paymentType === 'CARD' && 'Add Credit Card'}
                  {paymentOption.paymentType === 'PAYPAL' && 'PayPal'}
                  {paymentOption.paymentType === 'APPLE_PAY' && 'Apple Pay'}
                </label>
              )}
            {paymentOption.paymentType === 'ACH' && (
              <label
                className="payment-option"
                key={`${paymentOption.type}-${index}`}
              >
                <input
                  onChange={(ev) => {
                    store.updateCheckout({
                      paymentType: ev.target.value,
                      cardId: null,
                      cardBrand: null,
                      cardName: null,
                      cardNickname: null,
                      cardStripeId: null,
                    });
                  }}
                  type="radio"
                  value={paymentOption.paymentType}
                  checked={
                    currentCheckout.paymentType === 'ACH' &&
                    currentCheckout.cardId === null
                  }
                />
                Add ACH Account
              </label>
            )}
            {paymentOption.paymentType === currentCheckout.paymentType &&
              currentCheckout.paymentType !== 'BALANCE' &&
              !(
                currentCheckout.paymentType === 'CARD' &&
                currentCheckout.paymentType === 'ACH' &&
                currentCheckout.cardId !== null
              ) &&
              !(currentCheckout.cardId !== null) && (
                <div className="payment-method-details">
                  {renderPaymentMethodDetails({
                    paymentMethod: paymentOption.paymentType?.toUpperCase(),
                    store,
                    paypalValidate,
                    plaidValidate,
                    feeDescription: paymentOption.feeDescription,
                  })}
                </div>
              )}
          </Fragment>
        ))}
    </div>
  );
};

export default PaymentOptionSelector;
