import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { usePlaidLink } from 'react-plaid-link';
import PlaidLogo from '../../assets/images/brand/externalBrands/Plaid_logo_1.svg';

const WrappedPlaidButton = ({
  linkToken,
  validateFunc,
  onLinkStart,
  onLinkSuccess,
  onLinkError,
  onExitEvent,
  id,
  text = 'VERIFY WITH',
  hideIcon = false,
}) => {
  const onSuccess = useCallback(
    (token, metadata) => onLinkSuccess && onLinkSuccess(token, metadata),
    [onLinkSuccess],
  );

  const onExit = useCallback(
    (err, metadata) => err && onLinkError && onLinkError(err, metadata),
    [onLinkError],
  );

  // This will trigger quite a bit, called for basically every state change and
  // transition inside the iframe
  const onEvent = useCallback((eventName, metadata) => {
    global.IS_LOCAL_OR_DEV && console.log('onEvent', eventName, metadata);

    // This case covers when the user manually closes the plaid modal
    if (eventName === 'EXIT') {
      console.log('THERE', onExitEvent);
      onExitEvent && onExitEvent(eventName, metadata);
    }
  }, []);

  const config = {
    token: linkToken,
    env: global.IS_LOCAL_OR_DEV ? 'sandbox' : 'production',
    product: ['auth'],
    clientName: 'Cauze',
    onSuccess,
    onEvent,
    onExit,
  };

  const { open, ready, error } = usePlaidLink(config);

  const onClick = () => {
    // needs to be called twice to work properly
    validateFunc();

    if (validateFunc()) {
      onLinkStart && onLinkStart();
      open();
    }
  };

  return (
    <div className="plaid-button-container">
      <button
        id={id}
        className="plaid-button"
        onClick={onClick}
        disabled={!ready || error || !linkToken}
      >
        <span className="pay-text">{text}</span>
        {!hideIcon && <img className="plaid-image" src={PlaidLogo} />}
      </button>
    </div>
  );
};

WrappedPlaidButton.propTypes = {
  linkToken: PropTypes.string,
  validateFunc: PropTypes.func.isRequired,
  onLinkStart: PropTypes.func.isRequired,
  onLinkSuccess: PropTypes.func.isRequired,
  onLinkError: PropTypes.func.isRequired,
  onExitEvent: PropTypes.func.isRequired,
};

export default WrappedPlaidButton;
