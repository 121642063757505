import React from 'react';

const ErrorCard = ({
  message,
  className,
  support,
}) => (
  <div className={`error-card-container ${className} `}>
    <div className={`error-card ${support && 'support'}`}>
      <div className="error-card-message">{message}</div>
    </div>
  </div>
);

export default ErrorCard;
