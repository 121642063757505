import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import stripeOptions from '../../config/stripe';
import useWallet from '../../hooks/useWallet';
import withAuthRequired from '../../behaviors/withAuthRequired';
import withUserContext from '../../behaviors/withUserContext';

import AddFunds from 'components/dashboard/AddFunds';
import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Currency from 'components/Currency/Currency.js';
import ElementsWrapper from 'components/ElementsWrapper';

const defaultAchData = {
  country: 'us',
  currency: 'usd',
  routing_number: '',
  account_number: '',
  account_holder_name: '',
  account_holder_type: 'individual',
};

const WalletViewContent = observer(
  ({ activeEntity, uiStore, companyId, userId, walletStore }) => {
    const [userContext] = useState({ companyId, userId });
    const [showAddCard, setShowAddCard] = useState(false);
    const [showAddAch, setShowAddAch] = useState(false);
    const [achData, setAchData] = useState(defaultAchData);
    const [achSubmitting, setAchSubmitting] = useState(false);

    useWallet({ userContext });
    const stripe = useStripe();
    const elements = useElements();
    walletStore.setStripeObject(stripe);

    const isTrustedCompany = activeEntity?.balance?.allowNegative; // will only be true for companies
    const showAutopayOption =
      isTrustedCompany && !walletStore.hasAutopayEnabled; // top level check because only one can be active at a time
    const showRemoveAutopayOption =
      isTrustedCompany && walletStore.hasAutopayEnabled;

    const handleSubmit = (e) => {
      e.preventDefault();
      setAchSubmitting(true);
      walletStore.addAch({ achData, userContext }).then((success) => {
        if (success) {
          setShowAddAch(false);
          setAchSubmitting(false);
          setAchData(defaultAchData);
        }
      });
    };

    return (
      <div className="wallet-view flex-column">
        <Navbar />
        {walletStore.loadingPaymentMethods ? (
          <CauzeSpinner fullscreen />
        ) : (
          <div>
            <section>
              <div className="container">
                <div className="dashboard-container">
                  <h1 className="subtitle">Balance & Payment Methods</h1>
                  <div className="dashboard flex-row">
                    <AddFunds
                      title={
                        <div>
                          Balance: <Currency amount={walletStore.balance} />
                        </div>
                      }
                      onClickAddFunds={() =>
                        uiStore.openModal('SEND_GIFTS', {
                          giftType: 'SELF_GIFT',
                          companyId,
                          userId,
                          onSuccess: () =>
                            walletStore.getPaymentMethods({ userContext }),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="payment-methods container">
                <h1>Saved Payment Methods</h1>
                {walletStore.cards.map((card) => (
                  <div key={card.id}>
                    <span>
                      {card.brand} ending in {card.last4}
                    </span>
                    {card.accountType === 'ACH_PENDING' && (
                      <a
                        style={{ marginLeft: '1rem' }}
                        className="verify action-link"
                        onClick={() =>
                          uiStore.openModal('VERIFY_ACH', {
                            verifyAch: walletStore.verifyAch,
                            cardId: card.id,
                            cardName: `${card.brand} ending in ${card.last4}`,
                            userContext,
                          })
                        }
                      >
                        {' '}
                        Verify Account{' '}
                      </a>
                    )}
                    <a
                      className="action-grey remove"
                      onClick={() =>
                        walletStore.removeCard({
                          userContext,
                          cardId: card.id,
                        })
                      }
                    >
                      {' '}
                      Remove
                    </a>
                    {card.accountType === 'ACH' && showAutopayOption && (
                      <a
                        className="action-link"
                        style={{ marginLeft: '1rem' }}
                        onClick={() =>
                          uiStore.openModal('CONFIRM', {
                            message:
                              'I authorize Cauze to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.',
                            action: () => {
                              walletStore.setAutopayEnabled({
                                autopayEnabled: true,
                                cardId: card.id,
                                userContext,
                              });
                            },
                          })
                        }
                      >
                        Use for Autopay
                      </a>
                    )}
                    {card.accountType === 'ACH' &&
                      card.autopayEnabled &&
                      showRemoveAutopayOption && (
                        <a
                          className="action-link"
                          style={{ marginLeft: '1rem' }}
                          onClick={() =>
                            uiStore.openModal('CONFIRM', {
                              message:
                                'Please confirm that you want to disable ACH autopay',
                              action: () => {
                                walletStore.setAutopayEnabled({
                                  autopayEnabled: false,
                                  cardId: card.id,
                                  userContext,
                                });
                              },
                            })
                          }
                        >
                          Remove autopay from this account
                        </a>
                      )}
                  </div>
                ))}
                {!showAddCard && (
                  <a
                    style={{ paddingTop: '1rem' }}
                    className="action-link credit-card"
                    onClick={() => {
                      setShowAddAch(false);
                      setShowAddCard(true);
                    }}
                  >
                    Add a credit card
                  </a>
                )}
                {showAddCard && (
                  <div style={{ paddingTop: '1rem' }}>
                    <PaymentElement options={stripeOptions.cardElement} />
                    <Button
                      style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
                      className="is-small add-card"
                      onClick={() => {
                        walletStore
                          .registerNewCard({
                            elements: elements,
                            userContext,
                          })
                          .then((success) => success && setShowAddCard(false));
                      }}
                    >
                      Add card
                    </Button>
                  </div>
                )}
                {!showAddAch && (
                  <a
                    className="action-link add-ach"
                    onClick={() => {
                      setShowAddAch(true);
                      setShowAddCard(false);
                    }}
                  >
                    Add an ACH Account
                  </a>
                )}
                {showAddAch && (
                  <div>
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <input type="hidden" name="source" />
                      <div className="flex-column group">
                        <label htmlFor="name">
                          <span>Name on account</span>
                        </label>
                        <input
                          id="name"
                          className="input field"
                          placeholder="Name"
                          value={achData.account_holder_name}
                          onChange={(e) =>
                            setAchData({
                              ...achData,
                              account_holder_name: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="type">
                          <span>Type</span>
                        </label>
                        <select
                          id="type"
                          className="field"
                          value={achData.account_holder_type}
                          onChange={(e) =>
                            setAchData({
                              ...achData,
                              account_holder_type: e.target.value,
                            })
                          }
                        >
                          <option value="individual">Individual</option>
                          <option value="company">Company</option>
                        </select>
                        <label htmlFor="routing-number">
                          <span>Routing number</span>
                        </label>
                        <input
                          id="routing-number"
                          className="input field"
                          placeholder="Routing Number"
                          value={achData.routing_number}
                          onChange={(e) =>
                            setAchData({
                              ...achData,
                              routing_number: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="account-number">
                          <span>Account number</span>
                        </label>
                        <input
                          id="account-number"
                          className="input field"
                          placeholder="Account number"
                          value={achData.account_number}
                          onChange={(e) =>
                            setAchData({
                              ...achData,
                              account_number: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="ach-detail">
                        Two small deposits will be made in your account under
                        the name of <strong>ONE4ALL CHARITAB AMTS</strong>. When
                        you see those two deposits, come back here to verify
                        your account by entering the deposit amounts.
                      </div>
                      <div>
                        <Button
                          className="ach-submission"
                          isSubmitting={achSubmitting}
                          type="submit"
                        >
                          Send Verification
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </section>
          </div>
        )}
        <Footer />
      </div>
    );
  },
);

const WalletView = (props) => {
  return (
    <ElementsWrapper
      elementOptions={{
        mode: 'setup',
        setupFutureUsage: 'off_session',
        amount: undefined,
      }}
    >
      <WalletViewContent {...props} />
    </ElementsWrapper>
  );
};

export default withUserContext(
  withAuthRequired(inject('uiStore', 'walletStore')(WalletView)),
);
