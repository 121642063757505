import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import CharityDonateSearch from '../CharityDonateSearch/CharityDonateSearch';
import ThnksIcon1 from '../../assets/images/backgrounds/thnks-hero-icon1.svg';
import ThnksIcon2 from '../../assets/images/backgrounds/thnks-hero-icon2.svg';
import { getUserNameDefault } from '../../util/userUtils';

const Hero = ({ activeEntity, userToken, searchStore, uiStore, isShort }) => {
  const onSearch = () => {
    searchStore.getCharities();
    navigate('/donate/search');
  };

  const cobrandCompany = userToken?.company;
  const isCobrandThnks = cobrandCompany?.name === 'Thnks';
  const giftRecipient = userToken?.gift?.recipient;
  const greetingName = getUserNameDefault(giftRecipient || activeEntity);

  if (isCobrandThnks) {
    return (
      <div
        className={`hero-wrapper cobranding-${
          isCobrandThnks ? cobrandCompany.name : 'none'
        }`}
      >
        <div className="hero-title-wrapper">
          <h1 className="hero-title-cobranded hero-greeting">
            {greetingName && (`Hey, ${greetingName} `)} <br />
          </h1>
          <h1 className="hero-title-cobranded">SEARCH AND GIVE TO ANY NONPROFIT. UNLEASH YOUR GOOD</h1>
          <img className="hero-thnks-image image-left" src={ThnksIcon1} />
          <img className="hero-thnks-image image-right" src={ThnksIcon2} />
        </div>
        <div className="hero-search-wrapper">
          <CharityDonateSearch
            uiStore={uiStore}
            searchStore={searchStore}
            onSearch={onSearch}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`hero-wrapper cobranding-${
        isCobrandThnks ? cobrandCompany.name : 'none'
      } ${isShort && 'hero-short'}`}
    >
      {!isShort &&
      <div className="hero-title-wrapper">
        <h1 className="hero-title">
        Search and give to any nonprofit. Unleash Your Good.
        </h1>
      </div>
      }
      <div className="hero-search-wrapper">
        <CharityDonateSearch
          uiStore={uiStore}
          searchStore={searchStore}
          onSearch={onSearch}
        />
      </div>
    </div>
  );
};

Hero.propTypes = {
  activeEntity: PropTypes.object,
  searchStore: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
  userToken: PropTypes.object,
  isShort: PropTypes.bool,
};

export default Hero;
