import { observable, action, makeObservable, runInAction } from 'mobx';
import { navigate } from '@reach/router';
import mixpanel from 'mixpanel-browser';
import { reject } from 'lodash';

import { plaidLinkTokenQuery } from '../graphql/giftCheckout';
import { client as apolloClient } from '../util/apolloClient';
import {
  completePaymentProcess,
  getCheckoutVars,
  startPaymentProcess,
} from '../util/checkoutUtils';

import uiStore from './UiStore';
import authStore from './AuthStore';
import profileStore from './ProfileStore';

import charityPlaceholder from '../assets/images/icons/placeholder/placeholder-charity.svg';

import { paymentOptionsQuery } from '../graphql/paymentOptions';
import { pendingInvitesQuery } from '../graphql/user';

class UserGiftStore {
  @observable checkoutDetailsFail = false;
  @observable checkoutLoading = true;
  @observable awaitTokenCreation = false;
  @observable plaidLinkToken;
  @observable awaitCompleteCheckout = false;
  @observable awaitWebCheckout = false;
  @observable newCardToken = '';
  @observable checkoutDetails = {};
  @observable paymentOptions = [];
  @observable checkoutTitle = 'User Gift Checkout';
  @observable checkoutImage = charityPlaceholder;
  @observable backgroundCategory = '';
  @observable selectAllCharities = true;
  @observable showMarketingOption = true;

  @observable currentCheckout = {
    donationAmount: 2500,
    firstName: '',
    lastName: '',
    email: '',
    comment: '',
    emailIsPrivate: false,
    userId: '',
    phoneNumber: '',
  };

  @observable checkoutSuccess = {};
  @observable showConfirmationModal = false;
  @observable showPendingInvitesMessage = false;
  @observable pendingInvites;
  @observable showRewardModal = false;
  @observable showErrorModal = false;
  @observable errorModalText = '';
  @observable errorModalButtonText = '';
  @observable supportsApplePay = false;
  @observable applePaySetup = false;
  @observable paypalEnabled = false;
  @observable cardInputError = false;
  @observable returnPath;
  @observable referrerData = null;

  referrerContext = null;
  isAuthenticated = false;
  stashedCheckoutState = {};

  setStripeObject = (stripe) => {
    this.stripe = stripe;
  };

  setStripeElements = (elements) => {
    this.elements = elements;
  };

  setIsAuthenticated = (isAuthenticated = false) => {
    this.isAuthenticated = isAuthenticated;
  };

  @action updateCheckout = (props) => {
    this.currentCheckout = { ...this.currentCheckout, ...props };
  };

  @action resetCheckout = () => {
    this.checkoutLoading = true;
    this.checkoutDetails = {};
    this.currentCheckout = {
      donationAmount: 2500,
      firstName: '',
      lastName: '',
      email: '',
      comment: '',
      emailIsPrivate: false,
      userId: '',
      phoneNumber: '',
    };
    this.newCardToken = '';
    this.checkoutDetailsFail = false;
    this.awaitTokenCreation = false;
    this.awaitCompleteCheckout = false;
    this.showConfirmationModal = false;
    this.backgroundCategory = null;
    this.checkoutTitle = 'User Gift Checkout';
    this.checkoutImage = charityPlaceholder;
    this.showMarketingOption = true;
    this.cardInputError = false;
    this.showPendingInvitesMessage = false;
    this.pendingInvites = null;
    this.clearStashedCheckoutState();
  };

  @action getCheckoutDetails = async ({
    _gift,
    userId,
    userContext,
    activeEntity,
    emailIsPrivate = false,
    giftToken,
    giftAmount,
  }) => {
    if (userId) {
      this.returnPath = `/profile/${userId}`;
    }

    mixpanel.track('User Checkout Page Load', {
      userId,
      activeEntity,
      userContext,
      emailIsPrivate,
      giftToken,
      giftAmount,
    });

    let paymentType;
    let balance = 0;
    let cardId;
    let cardName;
    let cardBrand;
    let cardNickname;
    let referrerContext;

    runInAction(() => {
      this.checkoutLoading = true;
      this.checkoutDetailsFail = false;
      this.selectAllCharities = true;
      this.activeEntity = activeEntity;
    });

    const options = {
      variables: { userContext },
      query: paymentOptionsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      const result = await apolloClient.query(options);
      runInAction(() => {
        this.checkoutDetails = result.data;
      });

      // Process paymentOptions and set defaults
      if (giftToken) {
        paymentType = 'GIFT';
      } else {
        // Pull the default payment options off for display purposes.
        // The list of payment options the user picks from is generated in
        if (this.checkoutDetails.paymentOptions?.length > 0) {
          let isBankCard = false;
          this.checkoutDetails.paymentOptions.forEach((paymentOption) => {
            if (paymentOption.paymentType?.toLowerCase() === 'balance') {
              balance = this.checkoutDetails.paymentOptions[0].balance.total;
            }
            if (
              ['stripe_cc', 'card'].includes(
                paymentOption.paymentType.toLowerCase(),
              ) &&
              paymentOption.cards.length > 0
            ) {
              const defaultCard =
                paymentOption.cards.find((card) => card.is_default) ||
                paymentOption.cards[0];

              cardId = defaultCard.id;
              cardName = defaultCard.last4;
              cardBrand = defaultCard.brand;
              cardNickname = defaultCard.nickname;
              if (defaultCard.accountType?.toLowerCase() === 'ach') {
                isBankCard = true;
              }
            }
          });

          if (balance > 0) {
            paymentType = 'balance';
          } else if (cardId) {
            paymentType = isBankCard ? 'ach' : 'card';
          }
        }
      }

      // Default donation amount is custom for some balance amounts, and gifts
      let defaultDonationAmount = 2500;
      if (giftAmount) {
        defaultDonationAmount = giftAmount;
      } else if (
        paymentType?.toLowerCase() === 'balance' &&
        balance > 0 &&
        balance <= 2500
      ) {
        defaultDonationAmount = balance;
      }

      this.updateCheckout({
        userId,
        donationAmount: defaultDonationAmount,
        userToken: giftToken,
        paymentType,
        balance,
        cardId,
        cardName,
        cardNickname,
        cardBrand,
        referrerContext,
        emailIsPrivate,
        userContext,
      });
      runInAction(() => {
        this.checkoutLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.checkoutDetailsFail = true;
      });
    } finally {
      this.checkoutLoading = false;
    }
  };

  @action startCheckout = async () => {
    this.awaitTokenCreation = true;
    const paymentType = this.currentCheckout.paymentType.toUpperCase();
    const variables = {
      ...(this.currentCheckout.userId
        ? { userIds: [this.currentCheckout.userId] }
        : {}),
      ...(this.currentCheckout.firstName
        ? { senderFirstName: this.currentCheckout.firstName }
        : {}),
      ...(this.currentCheckout.lastName
        ? { senderLastName: this.currentCheckout.lastName }
        : {}),
      ...(this.currentCheckout.email
        ? { senderEmail: this.currentCheckout.email }
        : {}),
      giftType: 'USER_GIFT',
      amount: this.currentCheckout.donationAmount,
      paymentType: ['STRIPE_CC', 'APPLE_PAY'].includes(paymentType)
        ? 'CARD'
        : paymentType,
      comment: this.currentCheckout.comment,
      delayBalanceCheckout: true,
    };

    await startPaymentProcess({
      store: this,
      checkoutType: 'gift',
      variables: variables,
    });
  };

  executeRecaptcha = () => {
    const grecaptchaPromise = new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6Lc-e08jAAAAAEZ7BSXi10cVWgb2G_K2FoxSGsBD', {
            action: 'donationCheckout',
          })
          .then((recaptchaToken) => {
            resolve(recaptchaToken);
          }, reject);
      });
    })
      .then((token) => token)
      .catch((err) => {
        console.err(err);
        reject(err);
      });
    return grecaptchaPromise;
  };

  @action completeCheckout = async (auth = false) => {
    try {
      this.awaitCompleteCheckout = true;
      const variables = await getCheckoutVars({
        store: this,
        checkoutType: 'gift',
        auth,
      });

      const result = await completePaymentProcess({
        store: this,
        checkoutType: 'gift',
        variables,
        auth,
      });

      profileStore.getProfile();

      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      }

      return true;
    } catch (errors) {
      this.showConfirmationModal = false;
      this.awaitCompleteCheckout = false;

      if (Array.isArray(errors)) {
        if (errors[0].message) {
          this.popErrorModal(errors[0].message);
          return;
        }
      } else {
        this.popErrorModal(
          'There was a problem with your transaction. Support has been notified.',
        );
      }

      return false;
    }
  };

  @action cancelCheckoutConfirmation = () => {
    this.showConfirmationModal = false;
  };

  @action cancelPendingInvitesMessage = () => {
    this.showPendingInvitesMessage = false;
  };

  @action hideRewardSuccessModal = () => {
    this.showRewardModal = false;
  };

  getPaypalVars = () => ({
    currency: 'USD',
    commit: true,
    onSuccess: this.onPaypalSuccess,
    onError: this.onPaypalError,
    onCancel: this.onPaypalCancel,
    onOrderCreate: this.onPaypalOrderCreate,
  });

  @action onPaypalError = (error) => {
    this.checkoutLoading = false;
    this.popErrorModal(
      'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
    );
    mixpanel.track('User Gift Checkout Error with Paypal', { error });
  };

  @action onPaypalCancel = () => {
    this.checkoutLoading = false;
    this.hydrateCheckout();
    mixpanel.track('User Gift Checkout Cancelled with Paypal');
  };

  @action onPaypalSuccess = (orderDetails) => {
    this.checkoutLoading = true;
    try {
      const { name, email_address } = orderDetails.payer;
      this.updateCheckout({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
        firstName: name.given_name,
        lastName: name.surname,
        email: email_address,
      });

      this.startCheckout().then(() => {
        this.completeCheckout().then((checkoutComplete) => {
          this.clearStashedCheckoutState();
          this.checkoutLoading = false;
          if (checkoutComplete === true) {
            navigate('/checkout/user-gift-success');
          }
          mixpanel.track('User Gift Checkout Completed with Paypal');
        });
      });
    } catch (err) {
      this.checkoutLoading = false;
      this.hydrateCheckout();
      this.popErrorModal(
        'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
      );
    }
  };

  @action onPaypalOrderCreate = async () => {
    this.stashCheckoutState();
    this.updateCheckout({
      paymentType: 'PAYPAL',
      stripeToken: null,
      firstName: 'Gift',
      lastName: 'Recipient',
      email: 'cauzerecipient@cauze.com',
    });
    try {
      const promise = this.startCheckout();
      this.updateCheckout({
        firstName: '',
        lastName: '',
        email: '',
      });
      await promise;
    } catch (err) {
      this.updateCheckout({
        firstName: '',
        lastName: '',
        email: '',
      });
    }
    return this.getPaypalOrder();
  };

  getPaypalOrder = () => {
    mixpanel.track('User Gift Checkout Started with Paypal');

    if (this.currentCheckout.splitAmount) {
      // paypal uses decimal money representation
      let donationTotal =
        (this.currentCheckout.splitAmount.remaining ||
          this.currentCheckout.splitAmount.forCauze) / 100;
      let fee = this.currentCheckout.splitAmount.fee / 100;
      let orderTotal = this.currentCheckout.splitAmount.total / 100;

      return {
        intent: 'CAPTURE',
        purchase_units: [
          {
            items: [
              {
                name: 'Cauze Charitable Donation',
                description: `Cauze Charitable Donation to ${this.checkoutTitle}`,
                quantity: 1,
                unit_amount: { value: donationTotal, currency_code: 'USD' },
              },
              {
                name: 'Handling Fee',
                // description: `This charge does not go to Cauze`,
                quantity: 1,
                unit_amount: { value: fee, currency_code: 'USD' },
              },
            ],
            amount: {
              currency_code: 'USD',
              value: orderTotal,
              breakdown: {
                item_total: { value: orderTotal, currency_code: 'USD' },
              },
            },
            description: `Cauze Charitable Donation to ${this.checkoutTitle}`,
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
        },
      };
    }
  };

  roundToTwo = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

  @action checkEmailTokens = async () => {
    if (!this.currentCheckout.email || this.currentCheckout.email === '') {
      return;
    }
    const options = {
      variables: {
        email: this.currentCheckout.email.trim(),
      },
      mutation: pendingInvitesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await apolloClient.mutate(options);
      if (result.data.pendingInvites) {
        this.pendingInvites = {
          userIsUnclaimed: result.data.pendingInvites.userIsUnclaimed,
          hasMatches: result.data.pendingInvites.matches.find(
            (match) => match.active,
          ),
          hasGiftToken: result.data.pendingInvites.tokens.find(
            (token) =>
              token.type === 'COMPANY_INVITE_WITH_GIFT' ||
              token.type === 'GIFT',
          ),
          hasGroupInvite: result.data.pendingInvites.tokens.find(
            (token) =>
              token.type === 'COMPANY_INVITE_WITH_GIFT' ||
              token.type === 'COMPANY_INVITE',
          ),
        };
        this.showPendingInvitesMessage = true;
      }
    } catch (err) {
      this.showPendingInvitesMessage = false;
    }
  };

  @action resetPendingInvites = () => {
    this.pendingInvites = {};
    this.showPendingInvitesMessage = false;
  };

  @action generatePlaidLinkToken = async () => {
    if (!authStore.isAuthenticated) {
      return;
    }
    const options = {
      query: plaidLinkTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      this.plaidLinkToken = await apolloClient
        .query(options)
        .then((res) => res.data.plaidLinkToken);
    } catch (err) {
      // pass
    }
  };

  @action onPlaidStart = () => {
    mixpanel.track('User Gift Checkout Started with Plaid');

    // Stashing the checkout state so that paymentOptions can be rehydrated if the user or an
    // error closes the modal preemptively
    this.stashCheckoutState();
  };

  @action onPlaidSuccess = (token, metadata) => {
    this.checkoutLoading = true;

    try {
      this.updateCheckout({
        paymentData: {
          paymentTokenType: 'PLAID',
          paymentToken: token,
          paymentId: metadata.accounts[0]?.id,
        },
      });

      this.startCheckout().then(() => {
        this.completeCheckout()
          .then((checkoutComplete) => {
            if (checkoutComplete) {
              uiStore.closeModal();
              navigate('/checkout/user-gift-success');
            }

            this.clearStashedCheckoutState();
            mixpanel.track('User Gift Checkout Completed with Plaid');
            this.checkoutLoading = false;
          })
          .catch((err) => {
            this.popAchErrorModal(err);
          });
      });
    } catch (error) {
      this.checkoutLoading = false;
      this.onPlaidError(error);
      this.hydrateCheckout();
    }
  };

  @action onPlaidError = (error, _metadata) => {
    mixpanel.track('User Gift Checkout Error with Plaid');
    this.checkoutLoading = false;
    this.popAchErrorModal(error);
    this.hydrateCheckout();
  };

  @action onPlaidExitEvent = (_eventName, _metadata) => {
    mixpanel.track('User Gift Checkout User Closed Plaid Modal');
    this.hydrateCheckout();
    this.checkoutLoading = false;
  };

  popAchErrorModal = () =>
    this.popErrorModal(
      'There was an issue processing your Plaid ACH transaction. Support has been notified.',
    );

  @action onApplePayOrderCreate = async () => {
    this.stashCheckoutState();
    this.updateCheckout({
      paymentType: 'apple_pay',
      stripeToken: null,
      firstName: 'Gift',
      lastName: 'Recipient',
      email: 'cauzerecipient@cauze.com',
    });
    await this.startCheckout();
    this.updateCheckout({
      firstName: '',
      lastName: '',
      email: '',
    });
    return this.getApplePayOrder();
  };

  getApplePayOrder = () => {
    mixpanel.track('Gift Checkout Started with Apple Pay');

    if (this.currentCheckout.splitAmount) {
      return {
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Cauze',
          amount: this.currentCheckout.splitAmount.total,
        },
      };
    }

    return null;
  };

  @action onApplePayOrderComplete = async (paymentIntent) => {
    this.checkoutLoading = true;
    try {
      this.updateCheckout({
        paymentIntentId: paymentIntent.id,
        paymentType: 'APPLE_PAY',
      });

      if (!this.isAuthenticated) {
        await this.startCheckout();
      }

      const checkoutComplete = await this.completeCheckout();

      this.clearStashedCheckoutState();
      this.checkoutLoading = false;
      if (checkoutComplete === true) {
        navigate('/checkout/user-gift-success');
      }
      mixpanel.track('Donation Checkout Completed with Apple Pay');
    } catch (err) {
      this.checkoutLoading = false;
      this.hydrateCheckout();
      this.popErrorModal(
        'There was an issue processing your apple pay transaction. Please try again with card payment. Support has been notified.',
      );
    }
  };

  @action onNoCharitySelected = () => {
    this.popErrorModal('Please select a nonprofit.');
  };

  @action popErrorModal = (errorText, buttonText) => {
    this.errorModalText = errorText;
    this.errorModalButtonText = buttonText;
    this.toggleErrorModal();
  };

  @action onErrorModalClose = () => {
    this.toggleErrorModal();
    this.errorModalText = '';
    this.errorModalButtonText = '';
  };

  @action toggleErrorModal = () => {
    this.showErrorModal = !this.showErrorModal;
  };

  @action raiseCardInputError = () => {
    this.cardInputError = true;
  };

  @action dismissCardInputError = () => {
    this.cardInputError = false;
  };

  hydrateCheckout = () => {
    this.updateCheckout(this.stashedCheckoutState);
    this.clearStashedCheckoutState();
  };

  @action stashCheckoutState = () => {
    this.stashedCheckoutState = this.currentCheckout;
  };

  @action clearStashedCheckoutState = () => {
    this.stashedCheckoutState = {};
  };

  constructor() {
    makeObservable(this);
  }
}

const userGiftStore = new UserGiftStore();
export default userGiftStore;
