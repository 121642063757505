/* eslint-disable no-console */
import { observable, action, toJS, makeObservable, runInAction } from 'mobx';
import { navigate } from '@reach/router';
import { reject } from 'lodash';
import mixpanel from 'mixpanel-browser';

import charityPlaceholder from '../assets/images/icons/placeholder/placeholder-charity.svg';
import eventPlaceholder from '../assets/images/placeholders/placeholder-event.png';

import authStore from './AuthStore';
import profileStore from './ProfileStore';
import walletStore from './WalletStore';
import uiStore from './UiStore';

import {
  getFlatPaymentOptions,
  startPaymentProcess,
  completePaymentProcess,
  getCheckoutVars,
} from '../util/checkoutUtils';
import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';

import { checkoutDetailsQuery } from '../graphql/donationCheckout';
import { pendingInvitesQuery, userByIdQuery } from '../graphql/user';
import { plaidLinkTokenQuery } from '../graphql/giftCheckout';

class DonationCheckoutStore {
  @observable checkoutDetailsFail = false;
  @observable checkoutLoading = true;
  @observable awaitTokenCreation = false;
  @observable plaidLinkToken;
  @observable awaitCompleteCheckout = false;
  @observable awaitWebCheckout = false;
  @observable newCardToken = '';
  @observable checkoutDetails = {};
  @observable paymentOptions = [];
  @observable checkoutTitle = 'Donation Checkout';
  @observable checkoutImage = charityPlaceholder;
  @observable backgroundCategory = '';
  @observable charityBackground;
  @observable selectAllCharities = true;
  @observable showMarketingOption = true;

  @observable currentCheckout = {
    donationAmount: 2500,
    firstName: '',
    lastName: '',
    email: '',
    comment: '',
    emailIsPrivate: false,
  };

  @observable checkoutSuccess = {};
  @observable showConfirmationModal = false;
  @observable showPendingInvitesMessage = false;
  @observable pendingInvites;
  @observable showRewardModal = false;
  @observable showErrorModal = false;
  @observable errorModalText = '';
  @observable errorModalButtonText = '';
  @observable supportsApplePay = false;
  @observable applePaySetup = false;
  @observable paypalEnabled = false;
  @observable cardInputError = false;
  @observable returnPath;
  @observable referrerData = null;

  referrerContext = null;
  stashedCheckoutState = {};
  isAuthenticated = true;

  flatPaymentOptions = [{ paymentType: 'NEW_CARD', displayString: 'New Card' }];

  setStripeObject = (stripe) => {
    this.stripe = stripe;
  };

  setStripeElements = (elements) => {
    this.elements = elements;
  };

  @action updateCheckout = (props) => {
    this.currentCheckout = { ...this.currentCheckout, ...props };
  };

  @action resetCheckout = () => {
    this.checkoutLoading = true;
    this.checkoutDetails = {};
    this.currentCheckout = {
      donationAmount: 2500,
      firstName: '',
      lastName: '',
      email: '',
      comment: '',
    };
    this.newCardToken = '';
    this.checkoutDetailsFail = false;
    this.awaitTokenCreation = false;
    this.awaitCompleteCheckout = false;
    this.showConfirmationModal = false;
    this.charityBackground = null;
    this.backgroundCategory = null;
    this.checkoutTitle = 'Donation Checkout';
    this.checkoutImage = charityPlaceholder;
    this.showMarketingOption = true;
    this.cardInputError = false;
    this.showPendingInvitesMessage = false;
    this.pendingInvites = null;
    this.stripe = null;
    this.elements = null;
    this.clearStashedCheckoutState();
  };

  @action getCheckoutDetails = async ({
    _gift,
    charityIds,
    eventCharityIds,
    eventId,
    userContext,
    activeEntity,
    joinPurchaseId,
    emailIsPrivate = false,
    createEvent,
    giftToken,
    giftAmount,
  }) => {
    if (eventId) {
      this.returnPath = `/event/${eventId}`;
    }

    mixpanel.track('Donation Checkout Page Load', {
      eventId,
      charityIds,
      eventCharityIds,
      activeEntity,
      userContext,
      joinPurchaseId,
      emailIsPrivate,
      createEvent,
      giftToken,
      giftAmount,
    });

    let paymentType;
    let balance = 0;
    let onlyCharity;
    let cardId;
    let cardName;
    let cardBrand;
    let cardNickname;
    let matchIds = [];
    let referrerContext;
    runInAction(() => {
      this.checkoutLoading = true;
      this.checkoutDetailsFail = false;
      this.selectAllCharities = true;
      this.activeEntity = activeEntity;
    });

    // prevents other contexts from being referrer for now,
    // remove `&& uiStore.savedUiState.checkoutReferrer.referrerContext?.userId` in the future
    if (
      uiStore.savedUiState.checkoutReferrer &&
      uiStore.savedUiState.checkoutReferrer.referrerContext?.userId &&
      !createEvent
    ) {
      if (
        // UiStore...eventId called from old api is string && eventId is int, need to .toString() to compare
        `${uiStore.savedUiState.checkoutReferrer.eventId}` === `${eventId}` ||
        `${uiStore.savedUiState.checkoutReferrer.routeId}` === `${eventId}` ||
        `${uiStore.savedUiState.checkoutReferrer.purchaseId}` ===
          `${joinPurchaseId}`
      ) {
        referrerContext = toJS(
          uiStore.savedUiState.checkoutReferrer.referrerContext,
        );

        const options = {
          variables: {
            id: uiStore.savedUiState.checkoutReferrer.referrerContext.userId,
          },
          query: userByIdQuery,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_DEV ? 'all' : 'none',
        };

        try {
          const result = await apolloClient.query(options);
          runInAction(() => {
            this.referrerData = {
              id: result?.data?.data?.id,
              firstName: result?.data?.data?.firstName,
              lastName: result?.data?.data?.lastName,
            };
          });
        } catch (err) {
          this.referrerData = null;
        }
      } else {
        referrerContext = null;
      }
    }

    const charityIdsToInt = charityIds?.map((id) => parseInt(id));
    const eventCharityIdsToInt = eventCharityIds?.map((id) => parseInt(id));

    const options = {
      variables: {
        charityIds: !eventId ? charityIdsToInt || eventCharityIdsToInt : null,
        eventId: parseInt(eventId),
      },
      query: checkoutDetailsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      runInAction(() => {
        this.checkoutDetails = result.data.checkoutDetails;
      });

      if (charityIds?.length === 1 || eventId) this.setCharityTitleAndImage();

      if (this.checkoutDetails.charities.length > 0) {
        runInAction(() => {
          this.backgroundCategory =
            result.data.checkoutDetails.charities[0].category;
        });
      }
      // TODO: figure out gifting unauthed flow
      // Process paymentOptions and set defaults
      if (giftToken) {
        paymentType = 'GIFT';
      } else {
        // Pull the default payment options off for display purposes.
        // The list of payment options the user picks from is generated in
        // getFlatPaymentOptions
        if (result.data.checkoutDetails.paymentOptions.length > 0) {
          let isBankCard = false;
          result.data.checkoutDetails.paymentOptions.forEach(
            (paymentOption) => {
              if (paymentOption.paymentType === 'balance') {
                balance =
                  result.data.checkoutDetails.paymentOptions[0].balance.total;
              }
              if (
                paymentOption.paymentType === 'stripe_cc' &&
                paymentOption.paymentMethods.length > 0
              ) {
                const defaultCard =
                  paymentOption.paymentMethods.find((card) => card.isDefault) ||
                  paymentOption.paymentMethods[0];

                cardId = defaultCard.id;
                cardName = defaultCard.last4;
                cardBrand = defaultCard.brand;
                cardNickname = defaultCard.nickname;
                if (defaultCard.accountType?.toLowerCase() === 'ach') {
                  isBankCard = true;
                }
              }
            },
          );

          if (balance > 0) {
            paymentType = 'balance';
          } else if (cardId) {
            paymentType = isBankCard ? 'stripe_ach' : 'stripe_cc';
          }
        }
      }

      if (result.data.checkoutDetails.matches?.length > 0) {
        matchIds = result.data.checkoutDetails.matches
          .filter((match) => {
            if (
              Number.parseInt(match?.totalRemaining) <= 0 ||
              Number.parseInt(match?.userMatchLimit) <= 0
            ) {
              return false;
            }

            if (match?.currentEntityRemaining === undefined) {
              return true;
            }

            return Number.parseInt(match?.currentEntityRemaining) > 0;
          })
          .map((match) => match.id);
      }

      if (result.data.checkoutDetails.charities?.length === 1) {
        onlyCharity = result.data.checkoutDetails.charities[0].id;
      }

      if (result.data.checkoutDetails.charities.length > 1) {
        charityIds = result.data.checkoutDetails.charities.map(
          (charity) => charity.id,
        );
      } else if (eventCharityIds) {
        charityIds = eventCharityIds;
      }

      if (result.data.checkoutDetails.charities[0]?.image?.full) {
        runInAction(() => {
          this.charityBackground =
            result.data.checkoutDetails.charities[0].image.full;
        });
      }

      // TODO: figure out preferences for the new api call
      // // Check if they or their company has marketing notifications turned off
      // const marketingPreference = checkoutDetails.notificationPreferences?.find(
      //   np => np.channel === 'EMAIL' && np.category === 'MARKETING',
      // );

      // if (marketingPreference) {
      //   this.showMarketingOption = marketingPreference.active;
      //   if (!this.showMarketingOption) {
      //     _emailIsPrivate = true;
      //   }
      // }

      // Default donation amount is custom for some balance amounts, and gifts
      let defaultDonationAmount = 2500;
      if (giftAmount) {
        defaultDonationAmount = giftAmount;
      } else if (paymentType === 'BALANCE' && balance > 0 && balance <= 2500) {
        defaultDonationAmount = balance;
      }

      this.updateCheckout({
        donationAmount: defaultDonationAmount,
        userToken: giftToken,
        charityId: onlyCharity,
        charityIds,
        eventId,
        eventName: result.data.checkoutDetails?.event?.name || 'ADHOC',
        eventImage:
          result.data.checkoutDetails?.event?.image?.sm ||
          result.data.checkoutDetails?.event?.image?.md,
        joinPurchaseId,
        paymentType,
        balance,
        cardId,
        cardName,
        cardNickname,
        matchIds,
        cardBrand,
        referrerContext,
        emailIsPrivate,
        userContext,
        createEvent,
      });

      runInAction(() => {
        this.flatPaymentOptions = getFlatPaymentOptions({
          paymentOptions: this.checkoutDetails.paymentOptions,
          currentCheckout: this.currentCheckout,
          braintreeClientToken: uiStore.braintreeClientToken,
        });
        this.checkoutLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.checkoutDetailsFail = true;
      });
    }
  };

  @action startCheckout = async () => {
    this.awaitTokenCreation = true;
    const userDetails = {
      firstName: this.currentCheckout.firstName,
      lastName: this.currentCheckout.lastName,
      email: this.currentCheckout.email,
      zip: this.currentCheckout.zip || '',
    };
    if (!userDetails.firstName && userDetails.lastName) {
      userDetails.firstName = 'Giver';
    }

    const validateReferrerContext = () => {
      if (this.currentCheckout.referrerContext) {
        if (
          this.activeEntity?.type === 'USER' &&
          parseInt(this.activeEntity?.id) ===
            parseInt(this.currentCheckout.referrerContext.userId)
        ) {
          return null;
        } else {
          return parseInt(this.currentCheckout?.referrerContext?.userId);
        }
      } else return null;
    };

    if (this.currentCheckout.paymentType === 'BRAINTREE') {
      this.currentCheckout.paymentType = 'braintree';
    }

    const charityIdsToInt = this.currentCheckout?.charityIds
      ? this.currentCheckout?.charityIds?.map((id) => parseInt(id))
      : [this.currentCheckout?.charityId];
    const matchIdsToInt = this.currentCheckout?.matchIds?.map((id) =>
      parseInt(id),
    );

    const platformType = 'WEB';
    const paymentType = this.currentCheckout.paymentType.toUpperCase();
    const variables = {
      donationAmount: this.currentCheckout.donationAmount,
      charityIds: charityIdsToInt,
      platform: platformType,
      paymentType: paymentType === 'CARD' ? 'STRIPE_CC' : paymentType,
      comment: this.currentCheckout.comment,
      commentImageId: parseInt(this.currentCheckout.commentImageId),
      eventId: parseInt(this.currentCheckout.eventId),
      joinPurchaseId: parseInt(this.currentCheckout.joinPurchaseId),
      matchIds: matchIdsToInt,
      previewUrl: this.currentCheckout.previewUrl,
      referralLink: this.currentCheckout.referralLink,
      referralPath: this.currentCheckout.referralPath,
      referrerId: validateReferrerContext(),
      referrerType: 'USER',
      emailIsPrivate: this.currentCheckout.emailIsPrivate,
      cardBrand: this.currentCheckout.cardBrand,
      createEvent: false,
      eventName: this.currentCheckout.eventName,
      externalImageUrl: this.currentCheckout.externalImageUrl,
      userDetails: userDetails,
    };

    await startPaymentProcess({
      store: this,
      checkoutType: 'donation',
      variables,
    });
  };

  executeRecaptcha = () => {
    const grecaptchaPromise = new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6Lc-e08jAAAAAEZ7BSXi10cVWgb2G_K2FoxSGsBD', {
            action: 'donationCheckout',
          })
          .then((recaptchaToken) => {
            resolve(recaptchaToken);
          }, reject);
      });
    })
      .then((token) => token)
      .catch((err) => {
        console.err(err);
        reject(err);
      });
    return grecaptchaPromise;
  };

  @action completeCheckout = async () => {
    this.awaitCompleteCheckout = true;
    const variables = await getCheckoutVars({
      store: this,
      checkoutType: 'donation',
    });

    const paymentResult = await completePaymentProcess({
      store: this,
      variables,
      checkoutType: 'donation',
    });
    profileStore.getProfile();
    uiStore.saveUiState({ checkoutReferrer: {} });

    return paymentResult;
  };

  @action toggleAllCharities = () => {
    this.selectAllCharities = !this.selectAllCharities;
    if (this.selectAllCharities) {
      this.updateCheckout({
        charityIds: this.checkoutDetails.charities.map((charity) => charity.id),
      });
    } else {
      this.updateCheckout({ charityIds: [] });
    }
  };

  @action setCharityTitleAndImage = () => {
    let image;
    let title;

    if (this.checkoutDetails.event) {
      if (this.checkoutDetails.event.name) {
        title = this.checkoutDetails.event.name;
      }
      if (this.checkoutDetails.event.image) {
        image = this.checkoutDetails.event.image.md;
      } else {
        if (this.checkoutDetails.charities.length === 1) {
          if (
            this.checkoutDetails.charities[0].avatar &&
            this.checkoutDetails.charities[0].avatar.md
          ) {
            image = this.checkoutDetails.charities[0].avatar.md;
          } else {
            image = charityPlaceholder;
          }
        } else {
          image = eventPlaceholder;
        }
      }
      // else not an event
    } else {
      title = this.checkoutDetails.charities[0].name;

      if (
        this.checkoutDetails.charities.length === 1 &&
        this.checkoutDetails.charities[0].avatar &&
        this.checkoutDetails.charities[0].avatar.md
      ) {
        image = this.checkoutDetails.charities[0].avatar.md;
      } else {
        image = charityPlaceholder;
      }
    }
    this.checkoutTitle = title;
    this.checkoutImage = image;
  };

  @action cancelCheckoutConfirmation = () => {
    this.showConfirmationModal = false;
  };

  @action cancelPendingInvitesMessage = () => {
    this.showPendingInvitesMessage = false;
  };

  @action hideRewardSuccessModal = () => {
    this.showRewardModal = false;
  };

  getPaypalVars = () => ({
    currency: 'USD',
    commit: true,
    onSuccess: this.onPaypalSuccess,
    onError: this.onPaypalError,
    onCancel: this.onPaypalCancel,
    onOrderCreate: this.onPaypalOrderCreate,
  });

  @action onPaypalError = (error) => {
    this.checkoutLoading = false;
    this.popErrorModal(
      'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
    );
    mixpanel.track('Donation Checkout Error with Paypal', { error });
    global.IS_LOCAL_OR_DEV &&
      console.log('PayPal: Erroneous payment OR failed to load script!', error);
  };

  @action onPaypalCancel = (data) => {
    this.checkoutLoading = false;
    this.hydrateCheckout();
    mixpanel.track('Donation Checkout Cancelled with Paypal');
    global.IS_LOCAL_OR_DEV && console.log('Paypal: Cancelled payment!', data);
  };

  @action onPaypalSuccess = (orderDetails) => {
    this.checkoutLoading = true;
    try {
      this.updateCheckout({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
      });

      this.completeCheckout().then((checkoutComplete) => {
        this.clearStashedCheckoutState();
        this.checkoutLoading = false;
        if (checkoutComplete === true) {
          navigate('/checkout/success');
        }
        mixpanel.track('Donation Checkout Completed with Paypal');
      });
    } catch (err) {
      this.checkoutLoading = false;
      this.hydrateCheckout();
      this.popErrorModal(
        'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
      );
    }
  };

  @action onPaypalOrderCreate = async () => {
    this.stashCheckoutState();
    this.updateCheckout({ paymentType: 'PAYPAL', stripeToken: null });
    await this.startCheckout();
    return this.getPaypalOrder();
  };

  getPaypalOrder = () => {
    mixpanel.track('Donation Checkout Started with Paypal');

    if (this.currentCheckout.splitAmount) {
      // paypal uses decimal money representation
      let donationTotal = this.currentCheckout.splitAmount.remaining / 100;
      let fee = this.currentCheckout.splitAmount.fee / 100;
      let orderTotal = this.currentCheckout.splitAmount.total / 100;

      return {
        intent: 'CAPTURE',
        purchase_units: [
          {
            items: [
              {
                name: 'Cauze Charitable Donation',
                description: `Cauze Charitable Donation to ${this.checkoutTitle}`,
                quantity: 1,
                unit_amount: { value: donationTotal, currency_code: 'USD' },
              },
              {
                name: 'Handling Fee',
                // description: `This charge does not go to Cauze`,
                quantity: 1,
                unit_amount: { value: fee, currency_code: 'USD' },
              },
            ],
            amount: {
              currency_code: 'USD',
              value: orderTotal,
              breakdown: {
                item_total: { value: orderTotal, currency_code: 'USD' },
              },
            },
            description: `Cauze Charitable Donation to ${this.checkoutTitle}`,
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
        },
      };
    }
  };

  roundToTwo = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

  @action checkEmailTokens = async () => {
    if (!this.currentCheckout.email || this.currentCheckout.email === '') {
      return;
    }
    const options = {
      variables: {
        email: this.currentCheckout.email.trim(),
      },
      mutation: pendingInvitesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await apolloClient.mutate(options);
      if (result.data.pendingInvites) {
        this.pendingInvites = {
          userIsUnclaimed: result.data.pendingInvites.userIsUnclaimed,
          hasMatches: result.data.pendingInvites.matches.find(
            (match) => match.active,
          ),
          hasGiftToken: result.data.pendingInvites.tokens.find(
            (token) =>
              token.type === 'COMPANY_INVITE_WITH_GIFT' ||
              token.type === 'GIFT',
          ),
          hasGroupInvite: result.data.pendingInvites.tokens.find(
            (token) =>
              token.type === 'COMPANY_INVITE_WITH_GIFT' ||
              token.type === 'COMPANY_INVITE',
          ),
        };
        this.showPendingInvitesMessage = true;
      }
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
      this.showPendingInvitesMessage = false;
    }
  };

  @action resetPendingInvites = () => {
    this.pendingInvites = {};
    this.showPendingInvitesMessage = false;
  };

  @action generatePlaidLinkToken = async () => {
    if (!authStore.isAuthenticated) {
      return;
    }
    const options = {
      query: plaidLinkTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      this.plaidLinkToken = await apolloClient
        .query(options)
        .then((res) => res.data.plaidLinkToken);
    } catch (err) {
      console.log(err);
    }
  };

  @action onPlaidStart = () => {
    mixpanel.track('Donation Checkout Started with Plaid');

    // Stashing the checkout state so that paymentOptions can be rehydrated if the user or an
    // error closes the modal preemptively
    this.stashCheckoutState();
  };

  // token is the public token returned from the modal, which we send as part of completeCheckout
  // for the server to validate against stripe for the transaction.
  //
  // Very similar behavior to paypal
  @action onPlaidSuccess = async (token, metadata) => {
    this.checkoutLoading = true;
    try {
      walletStore
        .addBankAccount({
          publicToken: token,
          metadata: metadata,
          userContext: this.currentCheckout?.userContext,
        })
        .then(async (newAch) => {
          this.updateCheckout({
            paymentType: 'stripe_ach',
            cardId: +newAch.data.addBankAccount.id,
            cardBrand: newAch.data.addBankAccount.brand,
            cardName: newAch.data.addBankAccount.nickname,
            cardNickname: newAch.data.addBankAccount.nickname,
            cardStripeId: null,
            stripeToken: null,
          });
          await this.startCheckout();
          this.completeCheckout()
            .then((checkoutComplete) => {
              if (checkoutComplete) {
                uiStore.closeModal();
                navigate('/checkout/success');
              }

              this.clearStashedCheckoutState();
              mixpanel.track('Donation Checkout Completed with Plaid');
              this.checkoutLoading = false;
            })
            .catch((err) => {
              this.popAchErrorModal(err);
            });
        });
    } catch (error) {
      this.checkoutLoading = false;
      this.onPlaidError(error);
      this.hydrateCheckout();
    }
  };

  @action onPlaidError = (error, _metadata) => {
    mixpanel.track('Donation Checkout Error with Plaid');
    this.checkoutLoading = false;
    this.popAchErrorModal(error);
    this.hydrateCheckout();
  };

  @action onPlaidExitEvent = (_eventName, _metadata) => {
    mixpanel.track('Donation Checkout User Closed Plaid Modal');
    this.hydrateCheckout();
    this.checkoutLoading = false;
  };

  popAchErrorModal = (error) => {
    this.popErrorModal(
      'There was an issue processing your Plaid ACH transaction. Support has been notified.',
    );
    global.IS_LOCAL_OR_DEV && console.log('Plaid ACH Error', error);
  };

  @action onNoCharitySelected = () => {
    this.popErrorModal('Please select a nonprofit.');
  };

  @action popErrorModal = (errorText, buttonText) => {
    this.errorModalText = errorText;
    this.errorModalButtonText = buttonText;
    this.toggleErrorModal();
  };

  @action onErrorModalClose = () => {
    this.toggleErrorModal();
    this.errorModalText = '';
    this.errorModalButtonText = '';
  };

  @action toggleErrorModal = () => {
    this.showErrorModal = !this.showErrorModal;
  };

  @action raiseCardInputError = () => {
    this.cardInputError = true;
  };

  @action dismissCardInputError = () => {
    this.cardInputError = false;
  };

  hydrateCheckout = () => {
    this.updateCheckout(this.stashedCheckoutState);
    this.clearStashedCheckoutState();
  };

  @action stashCheckoutState = () => {
    this.stashedCheckoutState = this.currentCheckout;
  };

  @action clearStashedCheckoutState = () => {
    this.stashedCheckoutState = {};
  };

  @action onApplePayOrderCreate = async () => {
    this.stashCheckoutState();
    this.updateCheckout({
      paymentType: 'apple_pay',
      stripeToken: null,
    });
    await this.startCheckout();
    this.hydrateCheckout();
    return this.getApplePayOrder();
  };

  getApplePayOrder = () => {
    mixpanel.track('Donation Checkout Started with Apple Pay');

    if (this.currentCheckout.splitAmount) {
      return {
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Cauze',
          amount: this.currentCheckout.splitAmount.total,
        },
      };
    }

    return null;
  };

  @action onApplePayOrderComplete = async (paymentIntent) => {
    this.checkoutLoading = true;
    try {
      this.updateCheckout({
        paymentIntentId: paymentIntent.id,
        paymentType: 'APPLE_PAY',
      });

      this.completeCheckout().then((checkoutComplete) => {
        this.clearStashedCheckoutState();
        this.checkoutLoading = false;
        if (checkoutComplete === true) {
          navigate('/checkout/success');
        }
        mixpanel.track('Donation Checkout Completed with Apple Pay');
      });
    } catch (err) {
      this.checkoutLoading = false;
      this.hydrateCheckout();
      this.popErrorModal(
        'There was an issue processing your apple pay transaction. Please try again with card payment. Support has been notified.',
      );
    }
  };

  constructor() {
    makeObservable(this);
  }
}

const donationCheckoutStore = new DonationCheckoutStore();
export default donationCheckoutStore;
