import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Currency from '../Currency/Currency';

const AmountSelector = ({
  onAmountUpdate,
  amounts,
  defaultAmount,
  sendDefaultUpdate = true,
}) => {
  const [amount, setAmount] = useState(
    defaultAmount !== 0 ? defaultAmount : null,
  );
  const [customAmount, setCustomAmount] = useState(
    amount !== null && !amounts.includes(amount) ? amount / 100 : null,
  );
  const [customAmountFocused, setCustomAmountFocused] = useState(
    customAmount !== null,
  );

  useEffect(() => {
    if (defaultAmount && sendDefaultUpdate) {
      onAmountUpdate(defaultAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="amount-selector bg-dark-gray">
      {amounts.map(amt => (
        <button
          key={`${amt}`}
          className={classnames('amount-option', {
            'is-active': amount === amt,
          })}
          onClick={ev => {
            ev.preventDefault();
            setAmount(amt);
            onAmountUpdate(amt);
            setCustomAmountFocused(false);
          }}
        >
          <Currency amount={amt} />
        </button>
      ))}
      <button
        onClick={ev => ev.preventDefault()}
        className={classnames('amount-custom flex-row', {
          'is-active': customAmountFocused,
        })}
      >
        $
        <input
          onFocus={ev => {
            ev.preventDefault();
            onAmountUpdate(customAmount * 100);
            setCustomAmountFocused(true);
            setAmount(0);
          }}
          inputMode="numeric"
          placeholder="&nbsp;custom"
          type="text"
          value={customAmount === null ? '' : customAmount}
          onChange={ev => {
            ev.preventDefault();
            setCustomAmount(
              isNaN(parseInt(ev.target.value)) ? '' : parseInt(ev.target.value),
            );
            setAmount(0);
            onAmountUpdate(
              isNaN(parseInt(ev.target.value))
                ? 0
                : parseInt(ev.target.value) * 100,
            );
          }}
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />
      </button>
    </div>
  );
};

AmountSelector.propTypes = {
  amounts: PropTypes.arrayOf(PropTypes.number),
  onAmountUpdate: PropTypes.func.isRequired,
  defaultAmount: PropTypes.number,
  sendDefaultUpdate: PropTypes.bool,
};

AmountSelector.defaultProps = {
  amounts: [1000, 2500, 5000, 10000],
  defaultAmount: null,
};

export default AmountSelector;
